<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col-md-2">
        <!-- @click="printBarcode()" -->
        <button class="btn btn-primary btn-block" :disabled="isLoadingPrintBarcode" @click="handlePrintBarcode">
          <b-spinner v-if="isLoadingPrintBarcode" label="Loading..." variant="light" small />
          <span v-else>Print Barcode</span>
        </button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary btn-block" :disabled="isLoadingExort" @click="exportData()">
          <b-spinner v-if="isLoadingExort" label="Loading..." variant="light" small class="ml-1" />
          <span v-else>Export</span>
        </button>
      </div>
      <div class="col-md-2">
        <button v-b-modal.import-modal class="btn btn-primary btn-block">
          Import
        </button>
      </div>
      <div class="col-md-2">
        <button v-b-modal.form-modal class="btn btn-primary btn-block" @click="cleanUpForm()">
          Create
        </button>
      </div>
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>
                <input v-model="checkAll" type="checkbox">
              </th>
              <th>Product</th>
              <th>SKU Code</th>
              <th>Name</th>
              <th>Brand</th>
              <th>Unit</th>
              <th>Barcode</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr>
            <tr v-for="(item, index) in result.data" v-if="!isLoading" :key="index">
              <td>
                <!-- <div class="form-group">
                  <label :for="item.uuid" />
                  <div class="custom-control custom-checkbox"> -->
                <input v-model="exportFilter.uuids" type="checkbox" :value="item.uuid" :name="item.uuid">
                <!-- <label
                      class="custom-control-label"
                      :for="item.uuid"
                    />
                  </div>
                </div> -->
              </td>
              <td><img :src="item.image_url" style="max-width: 100px;"></td>
              <td>{{ item.sku_code || '-' }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.brand != null ? item.brand.name : '-' }}</td>
              <td>{{ item.uom.name }}</td>
              <td>{{ item.barcode_number ? 'Available' : 'Not Available' }}</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">

                  <button class="btn btn-success btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Detail" @click="detailItem(item)">
                    <feather-icon icon="EyeIcon" />
                  </button>

                  <button class="btn btn-info btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Edit" @click="editItem(item)">
                    <feather-icon icon="EditIcon" />
                  </button>

                  <button class="btn btn-danger btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Delete" @click="deleteItem(item.uuid)">
                    <feather-icon icon="Trash2Icon" />
                  </button>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

    <!-- Detail Modal -->
    <b-modal id="detail-modal" no-close-on-backdrop size="lg" title="Detail">

      <table v-if="detail != null" class="table table-hover">
        <tr>
          <td>SKU Name</td>
          <td>{{ detail.alias_name }}</td>
        </tr>
        <tr>
          <td>Kategori</td>
          <td>{{ detail.item != null ? detail.item.name : '-' }}</td>
        </tr>
        <tr>
          <td>Brand</td>
          <td>{{ detail.brand != null ? detail.brand.name : '-' }}</td>
        </tr>
        <tr>
          <td>UOM</td>
          <td>{{ detail.uom != null ? detail.uom.name : '-' }}</td>
        </tr>
        <tr>
          <td>Spesifikasi</td>
          <td>{{ detail.specification }}</td>
        </tr>
        <tr>
          <td>SKU Code</td>
          <td>{{ detail.sku_code }}</td>
        </tr>
        <tr>
          <td>UOM</td>
          <td>{{ detail.uom.name }}</td>
        </tr>
        <!-- <tr>
          <td>Variations</td>
          <td>
            <div
              v-for="(variation, index) in detail.variations"
              :key="index"
            >
              <strong>{{ variation.type }}:</strong>
              <span> {{ variation.values_name.length > 0 ? variation.values_name.join(', ') : '-' }}</span>
            </div>
          </td>
        </tr> -->
        <tr>
          <td>Image</td>
          <td><img :src="detail.image_url" style="max-width: 200px;"></td>
        </tr>
        <tr>
          <td>Barcode</td>
          <td>
            <vue-barcode v-if="detail.barcode_number != null" :value="detail.barcode_number" element-tag="img">
              Render failed
            </vue-barcode>
            <p v-else>
              -
            </p>
          </td>
        </tr>
      </table>
    </b-modal>
    <!-- Detail Modal -->

    <!-- Import Modal -->
    <b-modal id="import-modal" no-close-on-backdrop size="lg" title="Import" hide-footer>
      <div class="form">
        <div class="form-group">
          <label for="">File:</label><br>
          <input id="fileImport" ref="fileImport" type="file" @change="changeImportFile()">
        </div>

        <div v-if="isLoadingImport">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <button v-else class="btn btn-primary btn-sm" @click="importFile()">
          Import Data
        </button>
        <br>
        <hr>
        <small>Download import format <a href="/import-format/sku-import-format.xlsx">here</a></small>

      </div>
    </b-modal>
    <!-- Import Modal -->

    <!-- Add/Edit Modal -->
    <b-modal id="form-modal" size="lg" :title="editId != null ? 'Edit' : 'Add'">

      <div class="form-group">
        <label for="">Nama SKU:</label>
        <input v-model="formPayload.alias_name" type="text" class="form-control"
          :class="validations.hasOwnProperty('alias_name') && validations.alias_name.length > 0 ? 'is-invalid' : ''"
          placeholder="Nama SKU...">
        <small v-if="validations.hasOwnProperty('alias_name') && validations.alias_name.length > 0" class="text-danger">
          {{ validations.alias_name.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="item_uuid">Kategori:</label>
        <v-select id="item_uuid" v-model="formPayload.item_uuid" :options="formData.item" :reduce="item => item.uuid"
          label="name" placeholder="Type to search item..." taggable
          :create-option="item => ({ name: item, uuid: item })" @search="onSearchItem">
          <template slot="no-options">
            Type here to search item...
          </template>
        </v-select>
      </div>

      <div class="row">

        <div class="col-md-6">

          <div class="form-group">
            <label for="brand_uuid">Brand:</label>
            <v-select id="brand_uuid" v-model="formPayload.brand_uuid" :options="formData.brand"
              :reduce="brand => brand.uuid" label="name" placeholder="Type to search brand..." @search="onSearchBrand">
              <template slot="no-options">
                Type here to search brand...
              </template>
            </v-select>
          </div>

        </div>

        <div class="col-md-6">

          <div class="form-group">
            <label for="uom_uuid">Satuan:</label>
            <v-select id="uom_uuid" v-model="formPayload.uom_uuid" :options="formData.uom" :reduce="uom => uom.uuid"
              label="name" placeholder="Type to search UOM..." @search="onSearchUom">
              <template slot="no-options">
                Type here to search UOM...
              </template>
            </v-select>
          </div>

        </div>

      </div>

      <div class="form-group">
        <label for="">Image: (Optional)</label><br>
        <input id="file" ref="file" type="file" @change="changeImage()">
      </div>

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label for="">Spesifikasi:</label>
            <input v-model="formPayload.specification" type="text" class="form-control"
              :class="validations.hasOwnProperty('specification') && validations.specification.length > 0 ? 'is-invalid' : ''"
              placeholder="Spesifikasi...">
            <small v-if="validations.hasOwnProperty('specification') && validations.specification.length > 0"
              class="text-danger">
              {{ validations.specification.join(', ') }}
            </small>
          </div>

        </div>
      </div>

      <div class="form-group">
        <label for="">Barcode Number:</label>
        <div class="input-group">
          <input v-model="formPayload.barcode_number" type="text" class="form-control"
            :class="validations.hasOwnProperty('barcode_number') && validations.barcode_number.length > 0 ? 'is-invalid' : ''"
            placeholder="Barcode Number...">
          <div class="input-group-append">
            <button class="btn btn-success" @click="toggleBarcodeScanner()">
              Scan
            </button>
            <button class="btn btn-primary" @click="generateBarcode()">
              Generate
            </button>
          </div>
        </div>
        <small v-if="validations.hasOwnProperty('barcode_number') && validations.barcode_number.length > 0"
          class="text-danger">
          {{ validations.barcode_number.join(', ') }}
        </small>
      </div>

      <StreamBarcodeReader v-if="showBarcodeScanner" @decode="onDecode" @loaded="onLoaded" />

      <vue-barcode v-if="formPayload.barcode_number != null && formPayload.barcode_number != ''"
        :value="formPayload.barcode_number" element-tag="img">
        Render failed
      </vue-barcode>

      <hr>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button v-if="editId == null" variant="success" :disabled="isLoading" @click="createItem()">
          Save Item
        </b-button>
        <b-button v-else variant="success" :disabled="isLoading" @click="updateItem()">
          Save Item
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal -->

    <!-- Preview Print Barcode -->
    <b-modal id="form-export" size="lg" title="Preview Print">
      <vue-html2pdf pdf-format="a4" :enable-download="true" :preview-modal="true" ref="html2Pdf" :float-layout="false"
        :manual-pagination="true" filename="export-barcode" :pdf-quality="2">
        <section slot="pdf-content">
          <div class="d-flex flex-wrap p-1" style="gap: 4px">
            <div v-for="item, index in barcode_numbers" :key="index">
              <div style="border: 1px solid #000;">
                <h1 class="text-center mb-0 mt-25" style="font-size: 10px;color: black;">{{ item.name }}</h1>
                <vue-barcode :value="item.barcode_number" element-tag="img" :text="`${item.barcode_number}`">
                  Render failed
                </vue-barcode>
              </div>
              <div class="html2pdf__page-break" v-if="(index + 1) % 18 === 0" />
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="generateReport">
          Download Barcode
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-barcode-qty" size="lg" title="Jumlah Barcode">
      <div class="p-1">
        <label for="qty">Barcode Qty</label>
        <b-form-spinbutton id="qty" v-model="barcode_qty" min="1" />
      </div>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="handleBarcodeQty">
          Go To Download Barcode
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup, BImg, BFormSpinbutton
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import VueBarcode from 'vue-barcode'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueHtml2pdf from 'vue-html2pdf'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import moment from 'moment'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'SKU Master'
  },
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    VueBarcode,
    vSelect,
    StreamBarcodeReader,
    VueHtml2pdf,
    BImg,
    BFormSpinbutton
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
    checkAll: {
      handler(value) {
        if (value) {
          this.exportFilter.uuids = this.result.data.map(obj => obj.uuid)
        } else {
          this.exportFilter.uuids = []
        }
      },
      immediate: true,
    },
    'exportFilter.uuids': {
      handler(uuids) {
        this.barcode_numbers = []
        uuids.forEach(uuid => {
          if (uuid) {
            const item = this.result.data.find(item => item.uuid === uuid);
            if (item && item.barcode_number) {
              // Check if the barcode number is already in the array
              const exists = this.barcode_numbers.some(
                existing => existing.barcode_number === item.barcode_number
              );

              if (!exists) {
                this.barcode_numbers.push({
                  barcode_number: item.barcode_number,
                  name: item.name
                });
              }
            }
          }
        });
      },
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      checkAll: false,
      showBarcodeScanner: false,
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      isLoadingPrintBarcode: false,
      isLoadingExort: false,
      formPayload: Object,
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      exportFilter: {
        uuids: [],
      },
      importPayload: {
        file: null,
      },
      formData: {
        uom: [],
        brand: [],
        item: [],
        // variations: [],
      },
      validations: {},
      barcode_numbers: [],
      barcode_qty: 1,
    }
  },
  created() {
    this.getData()
    this.getItem()
    this.getBrand()
    this.getUom()
  },
  mounted() {
    // this.$http.get('/super-admin/variation/all')
    //   .then(response => {
    //     this.formData.variations = response.data.data
    //   })
  },
  methods: {
    handlePrintBarcode() {
      if (this.exportFilter.uuids.length === 0) {
        errorNotification(this, 'Oops!', 'Silahkan pilih item terlebih dahulu.')
        return;
      }
      if (this.exportFilter.uuids.length === 1) {
        this.$bvModal.show('modal-barcode-qty')
      } else {
        this.$bvModal.show('form-export')
      }
    },
    handleBarcodeQty() {
      this.barcode_numbers = []
      if (this.exportFilter.uuids[0]) {
        const item = this.result.data.find(item => item.uuid === this.exportFilter.uuids[0]);
        for (let index = 0; index < this.barcode_qty; index++) {
          this.barcode_numbers.push({
            barcode_number: item.barcode_number,
            name: item.name
          })
        }
      }
      if (this.barcode_numbers[0].barcode_number !== null) {
        this.$bvModal.hide('modal-barcode-qty')
        this.$bvModal.show('form-export')
      } else {
        errorNotification(this, 'Oops!', 'Barcode belum tersedia.')
        this.$bvModal.hide('modal-barcode-qty')
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    addVariation() {
      this.formPayload.variations.push({ type: '', values: [] })
    },
    removeVariation(index) {
      this.formPayload.variations.splice(index, 1)
    },
    changeImage() {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.image = this.$refs.file.files[0]
    },
    getItem() {
      this.$http.get(`super-admin/item?per_page=10`)
        .then(response => {
          this.formData.item = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    getBrand() {
      this.$http.get(`super-admin/brand?per_page=10`)
        .then(response => {
          this.formData.brand = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    getUom() {
      this.$http.get(`super-admin/uom?per_page=10`)
        .then(response => {
          this.formData.uom = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    onSearchItem(search, loading) {
      if (search.length) {
        loading(true)
        this.searchItem(loading, search, this)
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/item?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.item = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchBrand(search, loading) {
      if (search.length) {
        loading(true)
        this.searchBrand(loading, search, this)
      }
    },
    searchBrand: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/brand?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.brand = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUom(loading, search, this)
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/uom?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.uom = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    toggleBarcodeScanner() {
      this.showBarcodeScanner = !this.showBarcodeScanner
    },
    onDecode(result) {
      this.formPayload.barcode_number = result
    },
    onLoaded() {
      console.log('Barcode reader loaded')
    },
    generateBarcode() {
      this.formPayload.barcode_number = Math.floor(Math.random() * 90000000000) + 1
    },
    changeImportFile() {
      // eslint-disable-next-line prefer-destructuring
      this.importPayload.file = this.$refs.fileImport.files[0]
    },
    downloadImportFormat() {

    },
    importFile() {
      this.isLoadingImport = true

      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.importPayload) {
        if (this.importPayload.hasOwnProperty(key) && this.importPayload[key] != null) {
          form.append(key, this.importPayload[key])
        }
      }
      this.$http.post('/super-admin/product/import', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          successNotification(this, 'Success', 'Data successfully imported')
          this.isLoadingImport = false
          this.getData()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoadingImport = false
        })
    },
    detailItem(item) {
      this.detail = item

      this.$bvModal.show('detail-modal')
    },
    printBarcode() {
      this.isLoadingPrintBarcode = true

      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }
      this.filter.uuids = this.exportFilter.uuids

      this.$http.get('super-admin/product/export-barcode', {
        responseType: 'blob',
        params: this.checkAll ? null : this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileName = 'export-barcode.pdf'
        FileSaver.saveAs(blob, fileName)
        // const fileURL = URL.createObjectURL(blob);
        // window.open(fileURL, '_blank');

        context.isLoading = false
        context.isLoadingPrintBarcode = false
      }).catch(error => {
        context.isLoading = false
        context.isLoadingPrintBarcode = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    exportData(type = 'excel') {
      this.isLoadingExort = true

      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }
      this.filter.uuids = this.exportFilter.uuids

      this.$http.get('super-admin/product/export', {
        responseType: type == 'view' ? '' : 'blob',
        params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          const date = moment().format('DDMMYYYY')
          if (type == 'excel') {
            var fileName = `export-sku-master-${date}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-sku-master-${date}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
        context.isLoadingExort = false
      }).catch(error => {
        context.isLoading = false
        context.isLoadingExort = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        item_uuid: '',
        brand_uuid: '',
        uom_uuid: '',
        specification: '',
        alias_name: '',
        barcode_number: '',
        image: null,
        // variations: [],
      }

      // this.addVariation()
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage
      queryParams.product_type = 'default'

      this.$http.get('/super-admin/product', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    createItem() {
      const form = this.preparePayload()

      this.$http.post('/super-admin/product', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      this.$http.post(`/super-admin/product/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    preparePayload() {
      const form = new FormData()

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      // Assign variation uuid from values to single array
      // this.formPayload.variations.forEach(variation => {
      //   variation.values.forEach(value => {
      //     form.append('variations[]', value)
      //   })
      // })

      return form
    },
    editItem(item) {
      this.editId = item.uuid

      this.formData.uom = []
      this.formData.brand = []
      this.formData.item = []

      if (item.uom != null) {
        this.formData.uom.push({
          uuid: item.uom.uuid,
          name: item.uom.name,
        })
      }

      if (item.brand != null) {
        this.formData.brand.push({
          uuid: item.brand.uuid,
          name: item.brand.name,
        })
      }

      if (item.item != null) {
        this.formData.item.push({
          uuid: item.item.uuid,
          name: item.item.name,
        })
      }

      this.formPayload = {
        uuid: item.uuid,
        item_uuid: item.item != null ? item.item.uuid : '',
        brand_uuid: item.brand != null ? item.brand.uuid : '',
        uom_uuid: item.uom != null ? item.uom.uuid : '',
        specification: item.specification,
        alias_name: item.alias_name,
        // sku_code: item.sku_code,
        barcode_number: item.barcode_number,
        // variations: item.variations,
      }

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`super-admin/product/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
